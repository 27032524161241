const privacyTerm = `
<p>
인스타염 (https://instayeom.com) 이하 "회사" 또는 "인스타염 "이라 함)는 통신비밀보호법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보취급방침을 정하여 이용자 권익 보호에 최선을 다하고 있습니다. 인스타염 의 개인정보취급방침은 다음과 같은 내용을 담고 있습니다.
    1. 수집하는 개인정보의 항목 및 수집방법
    2. 개인정보의 수집 및 이용목적
    3. 개인정보 공유 및 제공
    4. 개인정보의 취급위탁
    5. 개인정보의 보유 및 이용기간
    6. 개인정보 파기절차 및 방법
    7. 이용자 및 법정대리인의 권리와 그 행사방법
    8. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항
    9. 개인정보의 기술적/관리적 보호 대책
    10. 개인정보관리 담당자의 연락처
    11. 기타
    12. 고지의 의무

1. 수집하는 개인정보의 항목 및 수집방법
    가. 수집하는 개인정보의 항목
        첫째, 회사는 회원가입, 원활한 고객상담, 각종 서비스의 제공을 위해 최초 회원가입 당시 아래와 같은 개인정보를 수집하고 있습니다.
            <회원가입 시>
            수집항목 : 아이디, 이메일, 비밀번호
        둘째, 서비스 이용과정이나 사업처리 과정에서 아래와 같은 정보들이 자동으로 생성되어 수집될 수 있습니다.
            - IP Address, 쿠키, 방문 일시, 서비스 이용 기록, 불량 이용 기록
        셋째, 부가 서비스 및 맞춤식 서비스 이용 또는 이벤트 응모 과정에서 해당 서비스의 이용자에 한해서만 아래와 같은 정보들이 수집될 수 있습니다.
            - 개인정보 추가 수집에 대해 동의를 받는 경우
        넷째, 유료 서비스 이용 과정에서 아래와 같은 결제 정보들이 수집될 수 있습니다.
            <무통장입금 시>
            수집항목 : 입금자 성함
            선택사항(현금영수증 이용시) : 휴대폰 번호

    나. 개인정보 수집방법
        인스타염 은 이용자가 자발적으로, 구체적으로 기입할 때만 개인정보를 수집하고 있습니다.

2. 개인정보의 수집 및 이용목적
    가. 회사가 개인정보를 수집하는 목적은 이용자의 신분과 서비스 이용의사를 확인하여 최적화되고 맞춤화된 서비스를 제공하기 위함입니다. 회사는 최초 회원가입 시 서비스 제공을 원활하게 하기 위해 필요한 최소한의 정보만을 수집하고 있으며 회사가 제공하는 서비스 이용에 따른 결제, 서비스발송 및 환불 등에 필요한 정보를 추가로 수집할 수 있습니다.
    나. 회사는 개인정보를 수집 및 이용목적 이외에 다른 용도로 이를 이용하거나 이용자의 동의 없이 제3자에게 이를 제공하지 않습니다.
    다. 회사는 다음과 같은 목적으로 개인정보를 수집하여 이용할 수 있습니다. 전자상거래 등에서의 소비자보호에 관한 법률, 국세기본법, 전자금융거래법 등 관련법령에 따라 주민등록번호 및 은행계좌번호의 수집• 보관이 불가피한 경우에는 이용자에게 고지하여 해당 정보를 수집할 수 있습니다.

3. 개인정보 공유 및 제공
    회사는 이용자들의 개인정보를 "2. 개인정보의 수집목적 및 이용목적"에서 고지한 범위 내에서 사용하며, 이용자의 사전 동의 없이는 동 범위를 초과하여 이용하거나 원칙적으로 이용자의 개인정보를 외부에 공개하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.
        - 이용자들이 사전에 공개에 동의한 경우
        - 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우

4. 개인정보의 취급위탁
    회사는 서비스 향상을 위해서 아래와 같이 개인정보를 위탁하고 있으며, 관계 법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을 규정하고 있습니다.
    서비스 향상을 위해서 이용자들의 개인정보를 외부전문업체에 위탁하여 처리할 수 있습니다. 개인정보의 처리를 위탁하는 경우에는 미리 그 사실을 이용자들에게 공지할 것입니다. 또한 위탁계약 등을 통하여 서비스제공자의 개인정보보호 관련 지시엄수, 개인정보에 관한 비밀유지, 제3자 제공의 금지 및 사고시의 책임부담 등을 명확히 규정하고 당해 계약내용을 서면 또는 전자적으로 보관하여 이용자의 권익을 보호하고 있습니다.

5. 개인정보의 보유 및 이용기간
    이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.
    가. 회사 내부 방침에 의한 정보보유 사유
        - 부정이용기록
            보존 이유 : 부정 이용 방지
            보존 기간 : 5년
    나. 관련법령에 의한 정보보유 사유
        상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.
        - 계약 또는 청약철회 등에 관한 기록
            보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률
            보존 기간 : 5년
        - 대금결제 및 재화 등의 공급에 관한 기록
            보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률
            보존 기간 : 5년
        - 소비자의 불만 또는 분쟁처리에 관한 기록
            보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률
            보존 기간 : 3년
        - 본인확인에 관한 기록
            보존 이유 : 정보통신 이용촉진 및 정보보호 등에 관한 법률
            보존 기간 : 6개월
        - 방문에 관한 기록
            보존 이유 : 통신비밀보호법
            보존 기간 : 3개월

6. 개인정보 파기절차 및 방법
    이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다.
    회사의 개인정보 파기절차 및 방법은 다음과 같습니다.
        가. 파기절차
            - 이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조)일정 기간 저장된 후 파기됩니다.
            - 동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.
        나. 파기방법
            - 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
            - 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.

7. 이용자 및 법정대리인의 권리와 그 행사방법
    - 이용자 및 법정 대리인은 언제든지 등록되어 있는 자신 혹은 당해 만 14세 미만 아동의 개인정보를 조회하거나 수정할 수 있으며 가입해지를 요청할 수도 있습니다.
    - 이용자 혹은 만 14세 미만 아동의 개인정보 조회, 수정을 위해서는 '개인정보변경'(또는 '회원정보수정' 등)을, 가입해지(동의철회)를 위해서는 "회원탈퇴"를 클릭하여 본인 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다.
    - 혹은 개인정보관리책임자에게 서면, 전화 또는 이메일로 연락하시면 지체 없이 조치하겠습니다.
    - 이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3 자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.
    - 회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 "5. 개인정보의 보유 및 이용기간"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.

8. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항
    회사는 이용자들에게 특화된 맞춤서비스를 제공하기 위해서 이용자들의 정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다. 쿠키는 웹사이트를 운영하는데 이용되는 서버(HTTP)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.
        가. 쿠키의 사용 목적
            이용자들이 방문한 인스타염 의 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 이용자 규모 등을 파악하여 이용자에게 최적화된 정보 제공을 위하여 사용합니다.
        나. 쿠키의 설치/운영 및 거부
            - 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.
            - 쿠키 설정을 거부하는 방법으로는 이용자가 사용하는 웹 브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.
            - 설정방법 예(인터넷 익스플로어의 경우) : 웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보
            - 다만, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 인스타염  일부 서비스는 이용에 어려움이 있을 수 있습니다.

9. 개인정보의 기술적/관리적 보호 대책
    회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을 강구하고 있습니다.
        가. 비밀번호 암호화
            인스타염  회원 아이디(ID)의 비밀번호는 암호화되어 저장 및 관리되고 있어 본인만이 알고 있으며, 개인정보의 확인 및 변경도 비밀번호를 알고 있는 본인에 의해서만 가능합니다.
        나. 해킹 등에 대비한 대책
            회사는 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다.
            개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있고, 최신 백신프로그램을 이용하여 이용자들의 개인정보나 자료가 누출되거나 손상되지 않도록 방지하고 있으며, 암호화통신 등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다.
            그리고 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있으며, 기타 시스템적으로 보안성을 확보하기 위한 가능한 모든 기술적 장치를 갖추려 노력하고 있습니다.
        다. 취급 직원의 최소화 및 교육
            회사의 개인정보관련 취급 직원은 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며, 담당자에 대한 수시 교육을 통하여 인스타염  개인정보취급방침의 준수를 항상 강조하고 있습니다.

10. 개인정보관리담당자 연락처
    귀하께서는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보관리책임자 혹은 담당부서로 신고하실 수 있습니다.
    회사는 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.

o 개인정보보호담당자
    성명 : 공영길
    소속 : 인스타염 

o 개인정보보호책임자
    성명 : 공영길
    소속 : 인스타염 


10. 기타
    인스타염 에 링크되어 있는 웹사이트들이 개인정보를 수집하는 행위에 대해서는 본 "인스타염  개인정보취급방침"이 적용되지 않음을 알려 드립니다.

11. 고지의 의무
    현 개인정보취급방침 내용에 변동사항이 있을 시에는 개정 최소 7일 전부터 홈페이지 '메인배너'를 통해 고지할 것입니다.
        - 공고일자 : 2021년 6월 20일
        - 시행일자 : 2021년 6월 20일</p>`;

export default privacyTerm;
