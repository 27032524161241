import { FC } from "react";
import styled from "styled-components";
import { TermContainer, TermContent, TermTitle } from "../../components/styles";
import privacyTerm from "../../terms/privacy";

const OverrideTermContent = styled(TermContent)`
  p {
    margin: 0;
    margin-top: 30px;
    white-space: pre-wrap;
  }
`;

const PrivacyTermPage: FC = () => {
  return (
    <TermContainer>
      <TermTitle>
        <b>인스타염 개인정보처리방침</b>
      </TermTitle>
      <OverrideTermContent dangerouslySetInnerHTML={{ __html: privacyTerm }} />
    </TermContainer>
  );
};

export default PrivacyTermPage;
